/*------------------------------------------------------
¦ 路由
¦ 
¦ Author: 大风
¦ Date: 2022-11-14 12:42:49
¦ Version: 1.0
¦ FilePath: src/router/index.js
¦------------------------------------------------------*/

import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'homePage',
    component: () => import(/*webpackChunkName: "homePage"*/ '@/views/homePage')
  },
  {
    path: '/category',
    name: 'category',
    component: () => import(/*webpackChunkName: "category"*/ '@/views/category')
  },
  {
    path: '/timetable',
    name: 'timetable',
    component: () =>
      import(/*webpackChunkName: "timetable"*/ '@/views/timetable')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/*webpackChunkName: "search"*/ '@/views/search')
  },
  {
    path: '/moreCourses',
    name: 'moreCourses',
    component: () =>
      import(/*webpackChunkName: "moreCourses"*/ '@/views/moreCourses')
  },
  {
    path: '/detailPages',
    name: 'detailPages',
    component: () =>
      import(/*webpackChunkName: "detailPages"*/ '@/views/detailPages')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  next()
})

/**
 * 路由
 * @returns {Object<any>}
 */
export default router
