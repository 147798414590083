/*------------------------------------------------------
¦ 文件名
¦ 文件描述
¦ 
¦ Author: 大风
¦ Date: 2022-11-14 12:41:50
¦ Version: 1.0
¦ FilePath: src/main1.js
¦------------------------------------------------------*/

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/index.less'

const app = createApp(App)

app.use(store).use(router).mount('#app')
