/*------------------------------------------------------
¦ 用户相关状态
¦ 
¦ Author: 大风
¦ Date: 2022-11-14 17:33:33
¦ Version: 1.0
¦ FilePath: src/store/modules/user.js
¦------------------------------------------------------*/

const state = {
  /**
   * 用户token
   * @type {string|null}
   */
  token: null,

  /**
   * 用户菜单
   * @type {Object[]}
   */
  userMenus: [],

  /**
   * 用户信息
   * @type {Object<any>}
   */
  userInfo: null,

  /**
   * 用户权限
   */
  userPermission: [],
  /**
   * 用户历史记录
   */
  historyList: []
}

const mutations = {
  /**
   * 更新用户token
   */
  UPDATE_TOKEN(state, payload) {
    state.token = payload
  },

  /**
   * 更新用户菜单
   */
  UPDATE_USER_MENUS(state, payload) {
    state.userMenus = payload
  },

  /**
   * 更新用户信息
   */
  UPDATE_USER_INFO(state, payload) {
    state.userInfo = payload
  },
  /**
   * 更新用户历史信息
   */
  UPDATE_HISTORY_LIST(state, payload) {
    state.historyList = payload
  },

  /**
   * 更新用户权限信息
   */
  UPDATE_USER_PERMISSION(state, payload) {
    state.userPermission = payload
  }
}

/**
 * 用户相关状态
 * @returns {Object<any>}
 */
export default {
  namespaced: true,
  state,
  mutations
}
