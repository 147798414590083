/*------------------------------------------------------
¦ 应用相关状态
¦ 
¦ Author: 大风
¦ Date: 2022-11-15 14:41:22
¦ Version: 1.0
¦ FilePath: src/store/modules/app.js
¦------------------------------------------------------*/

import appConfig from '@/config'

const state = {
  /**
   * 菜单类型
   * @type {number}
   */
  menuType: appConfig.appMenuType,

  /**
   * 激活的顶部菜单(这里使用菜单索引)
   * @type {number}
   */
  topBarMenuActive: 0
}

const mutations = {
  /**
   * 更新激活的顶部菜单
   */
  UPDATE_TOP_BAR_MENU_ACTIVE(state, payload) {
    state.topBarMenuActive = payload
  }
}

/**
 * 默认输出
 * @returns {Object<any>}
 */
export default {
  namespaced: true,
  state,
  mutations
}
