/*------------------------------------------------------
¦ 状态
¦ 
¦ Author: 大风
¦ Date: 2022-11-14 12:43:01
¦ Version: 1.0
¦ FilePath: src/store/index.js
¦------------------------------------------------------*/

import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import app from './modules/app'
import user from './modules/user'

/**
 * store
 * @returns {Object<any>}
 */
export default createStore({
  modules: {
    app,
    user
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ]
})
